import { render } from "preact";
import { Suspense } from "preact/compat";
import { Route, Switch } from "wouter-preact";
import { routes } from "./routes.ts";

export function App() {
  return (
    <>
      <main>
        <Switch>
          {routes.map(({ path, component: Component }) => (
            <Route key={path} path={path}>
              <Suspense fallback={<></>}>
                <Component />
              </Suspense>
            </Route>
          ))}
        </Switch>
      </main>
    </>
  );
}

render(<App />, document.getElementById("app"));
